// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import makeStyles from "@mui/styles/makeStyles";
import type { Row } from "../ReportTable/types/ReportTable.types";
import type { Filters, Params } from "../../store/reportTemplates/types";
import { getReportModel } from "../../store/reportTemplates/model";
import type { ReportType } from "../../store/reportTemplates/model";

type Props = {|
  columnIndex: number,
  style?: Object,
  row: Row,
  filters: Filters,
  PrefixRowComponent?: ?StatelessFunctionalComponent<{row: Row}>,
  params: Params,
  reportType: ReportType,
  rowIndex: number,
|}

const useStyles = makeStyles((theme) => ({
  tableCell: {
    padding: `0 ${theme.spacing(2)}`,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    wordBreak: "break-all",
    borderBottom: `1px solid ${theme.palette.borderColor}`,
    fontSize: `${theme.typography.fontSize}px`,
    "&.stripped": {
      background: `${theme.palette.background.darkDefault || theme.palette.background.default}`,
    },
    "&> div.MuiGrid-root": {
      justifyContent: "space-between",
    },
  },
}));

const ReportTableCell = ({
  columnIndex,
  style = {},
  row,
  filters,
  PrefixRowComponent,
  params,
  reportType,
  rowIndex,
}: Props) => {
  const classes = useStyles();
  const heading = Object.keys(filters);
  const PrepearedDataForCell = getReportModel(reportType).getFilterByKey(heading[columnIndex]).prepareData(row, params);

  return (
    <div
      style={style}
      className={`${classes.tableCell} ${rowIndex % 2 !== 0 ? "stripped" : ""}`}
    >
      {typeof PrefixRowComponent === "function" ? <PrefixRowComponent row={row} rowIndex={rowIndex} /> : null}
      {PrepearedDataForCell}
    </div>
  );
};

export default ReportTableCell;
