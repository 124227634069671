// @flow
import React from "react";
import { MenuItem, Select, SelectProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import type { GroupBy } from "../../store/reportTemplates/reportTemplates.constants";

interface Props extends SelectProps {
  selection: Array<{ label: string, value: GroupBy }>;
}

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 155,
    minHeight: 36,
    backgroundColor: "white",
  },
  popoverClasses: {
    overflow: "visible",
  },
}));

const GroupBySelect = (props: Props) => {
  const styles = useStyles();
  const {
    selection,
    children,
    value: selectValue = "day",
    ...omitProps
  } = props;
  return (
    <Select
      variant="outlined"
      className={styles.root}
      {...omitProps}
      value={selectValue}
      data-testid="groupBy-select"
      MenuProps={{ "data-testid": "select-menu", classes: { paper: styles.popoverClasses } }}
    >
      {selection.map(({ label, value }) => (
        <MenuItem key={value} value={value} data-testid="group-by-select-item">
          {label}
        </MenuItem>
      ))}
      {children}
    </Select>
  );
};

export default GroupBySelect;
