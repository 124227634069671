// @flow
import type { DropResult } from "@hello-pangea/dnd";
import {
  REPORT_TEMPLATE_RENAME,
  REPORT_TEMPLATE_REORDER,
  REPORT_TEMPLATES_ADD_FILER,
  REPORT_TEMPLATE_CLEAR_FILTERS,
  REPORT_TEMPLATE_DELETE_FILTER,
  REPORT_TEMPLATES_ADD_TEMPLATE,
  REPORT_TEMPLATE_TOGGLE_FILTER_SWITCHER_SAGA,
  REPORT_TEMPLATES_TOGGLE_TDS_CID,
  REPORT_TEMPLATE_SET_DATE_RANGE,
  REPORT_TEMPLATE_SET_TEMPLATE_NAME,
  REPORT_TEMPLATE_BAKE_REPORT_SAGA,
  SET_TEMPLATES_LIST,
  UPDATE_TEMPLATES_LIST_SAGA,
  REPORT_TEMPLATE_SHOW_REPORT_SAGA,
  REPORT_TEMPLATE_SAVE_SAGA,
  REPORT_TEMPLATES_SELECT_TEMPLATE,
  REPORT_TEMPLATES_GET_TEMPLATE_SAGA,
  START_FETCH_REPORT,
  ERROR_FETCH_REPORT,
  WARN_SHOW_REPORT,
  SUCCESS_SHOW_REPORT,
  SUCCESS_BAKE_REPORT,
  REPORT_TEMPLATE_SET_PERIOD,
  CLEAR_OLD_REPORT_DATA,
  REPORT_TEMPLATE_TOGGLE_SWITCHER_SAGA,
  REPORT_TEMPLATE_CHANGE_ORDER,
  REPORT_TEMPLATE_CHANGE_ACTIVE_FIELDS,
  REPORT_TEMPLATES_TOGGLE_FILTERS,
  REPORT_TEMPLATE_TOGGLE_FILTER_ALL_SAGA,
  REPORT_TEMPLATE_CHANGE_CURRENT_DAY,
  CHANGE_CALENDAR_DATE_SAGA,
  SET_TEMPLATE_GROUP_BY,
  SET_TEMPLATE_GROUP_BY_SAGA,
  REPORT_TEMPLATES_SET_HIDDEN_FILTER,
  CHANGE_CHECKED_LIST,
  CHANGE_TABLE_DATA,
} from "./reportTemplates.actions-types";
import type { Template } from "./types";
import type { GroupBy } from "./reportTemplates.constants";
import type { Row } from "../../components/ReportTable/types/ReportTable.types";

export const toggleTdsCid = (value: boolean) => ({
  type: REPORT_TEMPLATES_TOGGLE_TDS_CID,
  value,
});

export const addNewTemplate = () => ({
  type: REPORT_TEMPLATES_ADD_TEMPLATE,
});

export const setPeriod = (payload: string) => ({
  type: REPORT_TEMPLATE_SET_PERIOD,
  payload,
});

export const selectTemplate = (payload: Template) => ({
  type: REPORT_TEMPLATES_SELECT_TEMPLATE,
  payload,
});
export const selectTemplateById = (payload: {id: string}) => ({
  type: REPORT_TEMPLATES_GET_TEMPLATE_SAGA,
  payload,
});

export const showReport = () => ({
  type: REPORT_TEMPLATE_SHOW_REPORT_SAGA,
});
export const startFetchReport = () => ({
  type: START_FETCH_REPORT,
});
export const errorFetchReport = (payload:{isBaking?:boolean}) => ({
  type: ERROR_FETCH_REPORT,
  payload,
});
export const warnShowReport = () => ({
  type: WARN_SHOW_REPORT,
});
export const successShowReport = (payload: any) => ({
  type: SUCCESS_SHOW_REPORT,
  payload,
});
export const successBakeReport = (payload: any) => ({
  type: SUCCESS_BAKE_REPORT,
  payload,
});

export const updateTemplatesList = (opt?: {searchStr?: string}) => ({ type: UPDATE_TEMPLATES_LIST_SAGA, payload: opt });

export const toggleFilters = (filters : { [string]: Array<any> }) => ({
  type: REPORT_TEMPLATES_TOGGLE_FILTERS,
  filters,
});

export const setHiddenFilters = (payload : Array<string>) => ({
  type: REPORT_TEMPLATES_SET_HIDDEN_FILTER,
  payload,
});

export const reorderFilters = (payload: DropResult) => ({
  type: REPORT_TEMPLATE_REORDER,
  payload,
});

export const addFilter = (payload: { key: string, value: string[] }) => ({
  type: REPORT_TEMPLATES_ADD_FILER,
  payload,
});

export const deleteFilter = (payload: { key: string, value: any }) => ({
  type: REPORT_TEMPLATE_DELETE_FILTER,
  payload,
});

export const clearFilters = () => ({
  type: REPORT_TEMPLATE_CLEAR_FILTERS,
});

export const renameTemplate = (name: string) => ({
  type: REPORT_TEMPLATE_RENAME,
  payload: name,
});

export const setDateRange = ({ range, fieldKey } : {range: { from: Date, to: Date }, fieldKey: string}) => ({
  type: REPORT_TEMPLATE_SET_DATE_RANGE,
  range,
  fieldKey,
});

export const setTemplateName = (value: string) => ({
  type: REPORT_TEMPLATE_SET_TEMPLATE_NAME,
  payload: value,
});

export const saveTemplate = (asNewTemplate: boolean) => ({ type: REPORT_TEMPLATE_SAVE_SAGA, asNewTemplate });

export const bakeReport = () => ({ type: REPORT_TEMPLATE_BAKE_REPORT_SAGA });

export const setTemplatesList = ({ list }: {list: []}) => ({ type: SET_TEMPLATES_LIST, list });

export const clearOldReportData = () => ({ type: CLEAR_OLD_REPORT_DATA });

export const toggleSwitcherSaga = ({ value, checked } : { value: string, checked: boolean }) => ({
  type: REPORT_TEMPLATE_TOGGLE_SWITCHER_SAGA,
  value,
  checked,
});

export const toggleFiltersSaga = ({ filters, value }: { filters: Array<string>, value: boolean }) => ({
  type: REPORT_TEMPLATE_TOGGLE_FILTER_SWITCHER_SAGA,
  filters,
  value,
});
export const toggleFilterSaga = ({ filterName, value }: { filterName: string, value: boolean }) => ({
  type: REPORT_TEMPLATE_TOGGLE_FILTER_SWITCHER_SAGA,
  filters: [filterName],
  value,
});

export const toggleFilterAllSaga = (value: boolean) => ({
  type: REPORT_TEMPLATE_TOGGLE_FILTER_ALL_SAGA,
  value,
});

export const changeOrder = (fieldsOrder: Array<string>) => ({
  type: REPORT_TEMPLATE_CHANGE_ORDER,
  fieldsOrder,
});

export const changeActiveFields = (activeFields: {[string]: boolean}) => ({
  type: REPORT_TEMPLATE_CHANGE_ACTIVE_FIELDS,
  activeFields,
});

export const toggleCurrentDay = (value: boolean) => ({
  type: REPORT_TEMPLATE_CHANGE_CURRENT_DAY,
  value,
});

export const changeCalendarDate = ({ range, preset } : {range: {from: string, to: string}, preset: string}) => ({
  type: CHANGE_CALENDAR_DATE_SAGA,
  range,
  preset,
});

export const setTemplateGroupBySaga = (value: GroupBy) => ({ type: SET_TEMPLATE_GROUP_BY_SAGA, value });

export const setTemplateGroupBy = (value:string) => ({ type: SET_TEMPLATE_GROUP_BY, value });

export const changeCheckedList = (value: Array<number>) => ({
  type: CHANGE_CHECKED_LIST,
  value,
});

export const changeTableData = (value: Row[]) => ({
  type: CHANGE_TABLE_DATA,
  value,
});
