// @flow
import React from "react";
import { Box } from "@mui/material";
import { ColumnItemCard } from "@fas/ui-core";
import type { FilterItem } from "./types/ReportColumn.types";
import { getReportModel } from "../../store/reportTemplates/model";
import type { ReportType } from "../../store/reportTemplates/model";

type Props = {
  item: FilterItem;
  reportType: ReportType,
  addFilter: ({ key: string, value: any }) => void;
  onDeleteItem?: (item: FilterItem) => void;
  onChangeVisibilityItem?: (item: FilterItem) => void;
}

const ReportColumns = ({
  item, addFilter, reportType, onDeleteItem, onChangeVisibilityItem,
}: Props) => {
  const filterModel = getReportModel(reportType).getFilterByKey(item.key);
  return (
    <Box px={1} position="relative">
      <ColumnItemCard
        item={item}
        name={item.title}
        count={item.filter.length}
        disabled={!filterModel.editable()}
        onSubmit={({ key, value }) => {
          addFilter({ key, value: filterModel.getValue(value) });
        }}
        checkValues={filterModel.validate}
        validationErrorMsg={filterModel.getErrorMsg()}
        onDelete={onDeleteItem}
        onVisibilityChange={onChangeVisibilityItem}
        isVisible={item.isVisible}
      />
    </Box>
  );
};

export default React.memo<Props>(ReportColumns);
